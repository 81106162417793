import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import "../styles/SignIn.css"
import {
    Container,
    Input,
} from "@cloudscape-design/components";
import React from "react";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import userPool from "../cognito";
import { useNavigate } from "react-router-dom";

export default function Register() {
    const [email, setEmail] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [password2, setPassword2] = React.useState("");
    const navigate = useNavigate();

    async function register() {
        if (password !== password2) {
            return alert("Passwords do not match");
        }
        const attributeEmail = new CognitoUserAttribute({Name: 'email', Value: email});
        const attributeFirstName = new CognitoUserAttribute({Name: 'given_name', Value: firstName});
        const attributeLastName = new CognitoUserAttribute({Name: 'family_name', Value: lastName});

        userPool.signUp(
            email,
            password, [attributeEmail, attributeFirstName, attributeLastName],
            [],
            (error, result)=> {
            if (error) {
                alert(error);
            } else {
                console.log('user name is ' + result?.user.getUsername());
                navigate("/verify", {state: { email }});
            }
        });
    }

    return (
      <>
          <Container
              className={"InputsBox"}
              header={
                  <Header
                      variant="h3"
                      description="Provide the details below"
                  >
                      Register
                  </Header>
              }
              footer={<Button onClick={register}>Register</Button>}>
              <SpaceBetween size={"m"}>
                  <Input type={"email"} value={email} placeholder={"Email"} onChange={({ detail }) => setEmail(detail.value)}/>
                  <Input type={"text"} value={firstName} placeholder={"First name"} onChange={({ detail }) => setFirstName(detail.value)}/>
                  <Input type={"text"} value={lastName} placeholder={"Last name"} onChange={({ detail }) => setLastName(detail.value)}/>
                  <Input type={"password"} value={password} placeholder={"Password"} onChange={({ detail }) => setPassword(detail.value)}/>
                  <Input type={"password"} value={password2} placeholder={"Retype Password"} onChange={({ detail }) => setPassword2(detail.value)}/>
              </SpaceBetween>
          </Container>
      </>

  );
}
