import React from "react";
import AppLayout from "@cloudscape-design/components/app-layout";
import {
    ButtonDropdownProps,
    SideNavigation,
    SideNavigationProps,
    TopNavigation
} from "@cloudscape-design/components";
import userPool from "../cognito";
import {Outlet, useLoaderData, useLocation, useNavigate} from "react-router-dom";
import {CognitoUserSession} from "amazon-cognito-identity-js";

const SIGN_OUT: string = "signout";

export default function Home() {
    const session = useLoaderData() as CognitoUserSession;
    const navigate = useNavigate();
    const navItems: SideNavigationProps.Item[] = [
        {
            type: 'section',
            text: 'Manage',
            items: [
                { type: 'link', text: 'Pages', href: 'pages' },
                { type: 'link', text: 'Users', href: 'users' },
            ],
        },
        {
            type: 'section',
            text: 'Set up',
            items: [
                { type: 'link', text: 'Database', href: 'database' },
                { type: 'link', text: 'Authentication', href: 'authentication' },
                { type: 'link', text: 'Analytics', href: 'analytics' },
                { type: 'link', text: 'Predictions', href: 'predictions' },
                { type: 'link', text: 'Interactions', href: 'interactions' },
                { type: 'link', text: 'Notifications', href: 'notifications' },
            ],
        },
    ];

    const onDropdownClick = (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>)=> {
        switch(event.detail.id) {
            case SIGN_OUT:
                userPool.getCurrentUser()?.signOut();
                navigate('/signin');
        }
    };
    const [activeHref, setActiveHref] = React.useState(useLocation().pathname.split("/")[1]);
    return (
      <>
          <TopNavigation
              identity={{
                  onFollow: (event)=> {event.preventDefault(); navigate('/'); setActiveHref('/')},
                  href: ".",
                  title: "Service",
                  logo: {
                      src:
                          "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSIzMXB4IiB2aWV3Qm94PSIwIDAgNDMgMzEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxnIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxyZWN0IGZpbGw9IiMyMzJmM2UiIHN0cm9rZT0iI2Q1ZGJkYiIgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSI0MiIgaGVpZ2h0PSIzMCIgcng9IjIiPjwvcmVjdD4KICAgICAgICA8dGV4dCBmb250LWZhbWlseT0iQW1hem9uRW1iZXItUmVndWxhciwgQW1hem9uIEVtYmVyIiBmb250LXNpemU9IjEyIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHRzcGFuIHg9IjkiIHk9IjE5Ij5Mb2dvPC90c3Bhbj4KICAgICAgICA8L3RleHQ+CiAgICA8L2c+Cjwvc3ZnPgo=",
                      alt: "Service"
                  }
              }}
              utilities={[
                  {
                      type: "button",
                      text: "Link",
                      href: "#",
                      external: false,
                      externalIconAriaLabel: " (opens in a new tab)",
                  },
                  {
                      type: "button",
                      iconName: "notification",
                      title: "Notifications",
                      ariaLabel: "Notifications (unread)",
                      badge: true,
                      disableUtilityCollapse: false,
                  },
                  {
                      type: "menu-dropdown",
                      iconName: "settings",
                      ariaLabel: "Settings",
                      title: "Settings",
                      items: [
                          {
                              id: "settings-org",
                              text: "Organizational settings"
                          },
                          {
                              id: "settings-project",
                              text: "Project settings",
                          }
                      ]
                  },
                  {
                      type: "menu-dropdown",
                      text: `${session.getIdToken().payload.given_name} ${session.getIdToken().payload.family_name}`,
                      description: session.getIdToken().payload.email,
                      iconName: "user-profile",
                      onItemClick: onDropdownClick,
                      items: [
                          { id: "profile", text: "Profile" },
                          { id: "preferences", text: "Preferences" },
                          { id: "security", text: "Security" },
                          {
                              id: "support-group",
                              text: "Support",
                              items: [
                                  {
                                      id: "documentation",
                                      text: "Documentation",
                                      href: "#",
                                      external: true,
                                      externalIconAriaLabel:
                                          " (opens in new tab)"
                                  },
                                  { id: "support", text: "Support" },
                                  {
                                      id: "feedback",
                                      text: "Feedback",
                                      href: "#",
                                      external: true,
                                      externalIconAriaLabel:
                                          " (opens in new tab)",
                                  }
                              ]
                          },
                          { id: SIGN_OUT, text: "Sign out" }
                      ]
                  }
              ]}
              i18nStrings={{
                  searchIconAriaLabel: "Search",
                  searchDismissIconAriaLabel: "Close search",
                  overflowMenuTriggerText: "More",
                  overflowMenuTitleText: "All",
                  overflowMenuBackIconAriaLabel: "Back",
                  overflowMenuDismissIconAriaLabel: "Close menu"
              }}
          />
          <AppLayout
              stickyNotifications
              toolsHide
              headerSelector="#header"
              ariaLabels={{ navigationClose: 'close' }}
              navigation={
                  <SideNavigation activeHref={activeHref}
                                  items={navItems}
                                  onFollow={(event) => {
                                      if (!event.detail.external) {
                                          event.preventDefault();
                                          setActiveHref(event.detail.href);
                                          navigate(event.detail.href);
                                      }
                                  }}
              />}
              contentType="table"
              content={<Outlet context={session} />}
          />
      </>
  );
}
