import {Box, ContentLayout, Header} from "@cloudscape-design/components";
import {CognitoUserSession} from "amazon-cognito-identity-js";
import {useOutletContext, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import axios from "axios";
import apigateway from "../../apigateway";
import IPage from "../../interfaces/Page"

export default function Page() {
    const session: CognitoUserSession = useOutletContext();
    const params = useParams();
    const [page, setPage] = React.useState<IPage>();
    useEffect(() => {
        axios.get(apigateway + `/items/${params.id}`, {
            headers: {
                Authorization: session.getIdToken().getJwtToken()
            }
        }).then((result=> {
            console.log(result.data);
            setPage(result.data);
        }));
    }, [session, params.id])
    return <ContentLayout
    header={<Box padding={"m"}><Header variant="awsui-h1-sticky" description={"These are the details for this page"}>
        {page?.itemId}
    </Header></Box>}>
    </ContentLayout>
}
