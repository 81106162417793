import {Box, Table, TableProps, Link, Modal, Flashbar} from "@cloudscape-design/components";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import React, { useEffect } from "react";
import {useOutletContext, useNavigate, useLocation} from "react-router-dom";
import axios from "axios";
import apigateway from "../../apigateway";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import IPage from "../../interfaces/Page"
import {FlashbarProps} from "@cloudscape-design/components/flashbar/interfaces";

export default function Pages() {
    const session: CognitoUserSession = useOutletContext();
    const navigate = useNavigate();
    const {state} = useLocation();
    const [pages, setPages] = React.useState<IPage[]>([]);
    const [selectedItems, setSelectedItems] = React.useState<IPage[]>([]);
    const [visible, setVisible] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isDeleteLoading, setDeleteLoading] = React.useState(false);
    const [flashItems, setFlashItems] = React.useState<FlashbarProps.MessageDefinition[]>(state?.message?[{
        type: "success",
        dismissible: true,
        onDismiss: () => {navigate('/pages', {state: {}, replace: true}); setFlashItems([])},
        dismissLabel: "Dismiss message",
        content: state.message,
    }]: []);
    const columnDefinitions: TableProps.ColumnDefinition<IPage>[] = [
        {
            id: 'itemId',
            cell: (item: IPage) => <Link onFollow={()=> navigate(item.itemId)}>{item.itemId}</Link>,
            header: 'Item Id',
            minWidth: 160,
        },
        {
            id: 'input',
            cell: (item: IPage) => item.input,
            header: 'One',
            minWidth: 160,
        },
        {
            id: 'input2',
            cell: (item: IPage) => item.input2,
            header: 'Two',
            minWidth: 160,
        },
        {
            id: 'input3',
            cell: (item: IPage) => item.input3,
            header: 'Three',
            minWidth: 160,
        },
    ];
    useEffect(() => {
        axios.get(apigateway + '/items', {
            headers: {
                Authorization: session.getIdToken().getJwtToken()
            }
        }).then((result=> {
            setPages(result.data);
            setIsLoading(false);
            console.log(result.data);
        }));
    }, [session])

    const deleteItem = () => {
        setDeleteLoading(true);
        axios.delete(apigateway +`/items/${selectedItems[0].itemId}`, {
            headers: {
                Authorization: session.getIdToken().getJwtToken()
            }
        }).then((()=> {
            axios.get(apigateway + '/items', {
                headers: {
                    Authorization: session.getIdToken().getJwtToken()
                }
            }).then((result=> {
                setDeleteLoading(false);
                setVisible(false);
                setPages(result.data);
                setFlashItems([{
                    type: "success",
                    dismissible: true,
                    onDismiss: () => {navigate('/pages', {state: {}, replace: true}); setFlashItems([])},
                    dismissLabel: "Dismiss message",
                    content: "Page deleted successfully",
                }]);
            }));
        }));
    }

    return (
        <>
            <Table
                items={pages}
                columnDefinitions={columnDefinitions}
                variant="full-page"
                loading={isLoading}
                header={
                    <Header
                        variant="awsui-h1-sticky"
                        counter={`(${pages.length})`}
                        description={"This is a list of objects you can modify"}
                        actions={
                            <SpaceBetween size="xs" direction="horizontal">
                                <Button disabled={selectedItems.length === 0}
                                        onClick={()=> navigate(selectedItems[0].itemId)}
                                >View details</Button>
                                <Button disabled>Edit</Button>
                                <Button disabled={selectedItems.length === 0}
                                        onClick={()=> setVisible(true)}>
                                    Delete
                                </Button>
                                <Button variant="primary"
                                        onClick={()=> navigate('create')}
                                >Create page</Button>
                            </SpaceBetween>
                        }
                    >
                        <Box margin={"xs"} />
                        <Flashbar items={flashItems}/>
                        <Box margin={"xs"} />
                        Pages
                    </Header>
                }
                selectionType={"single"}
                onSelectionChange={(e)=> setSelectedItems(e.detail.selectedItems)}
                selectedItems={selectedItems}
                stickyHeader={true}
                empty={
                    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                        <SpaceBetween size="xxs">
                            <div>
                                <b>No pages</b>
                                <Box variant="p" color="inherit">
                                    You don't have any pages.
                                </Box>
                            </div>
                            <Button>Create page</Button>
                        </SpaceBetween>
                    </Box>
                }
            />
            <Modal visible={visible}
                   onDismiss={()=>setVisible(false)}
                   footer={
                       <Box float="right">
                           <SpaceBetween direction="horizontal" size="xs">
                               <Button variant="link" onClick={()=> setVisible(false)}>Cancel</Button>
                               <Button variant="primary" loading={isDeleteLoading} onClick={deleteItem}>Ok</Button>
                           </SpaceBetween>
                       </Box>
                   }
                   header="Delete item">Are you sure you want to delete this?</Modal>
        </>
    );
}
