import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import "../styles/SignIn.css"
import {
    Container,
    Input,
} from "@cloudscape-design/components";
import React from "react";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userPool from "../cognito";
import { useNavigate } from "react-router-dom";

export default function SignIn() {
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const navigate = useNavigate();

    async function signIn() {
        const authenticationDetails = new AuthenticationDetails({Username: email, Password: password});
        const cognitoUser = new CognitoUser({Username : email, Pool : userPool});
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (a) {
                cognitoUser.getUserAttributes((error, details)=> {
                    console.log(details);
                });
                navigate("/");
            },
            onFailure: function(err: any) {
                alert(err);
            },

        });
    }

    return (
      <>
          <Container
              className={"InputsBox"}
              header={
                  <Header
                      variant="h3"
                      description="Enter your email and password"
                  >
                      Login
                  </Header>
              }
              footer={<Button onClick={signIn}>Sign in</Button>}>
              <SpaceBetween size={"m"}>
                  <Input type={"email"} value={email} placeholder={"Email"} onChange={({ detail }) => setEmail(detail.value)}/>
                  <Input type={"password"} value={password} placeholder={"Password"} onChange={({ detail }) => setPassword(detail.value)}/>
              </SpaceBetween>
          </Container>
      </>

  );
}
