import {
    Box,
    Button,
    Container,
    ContentLayout,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween
} from "@cloudscape-design/components";
import {CognitoUserSession} from "amazon-cognito-identity-js";
import {useOutletContext, useNavigate} from "react-router-dom";
import React from "react";
import axios from "axios";
import apigateway from "../../apigateway";

export default function PageCreate() {
    const session: CognitoUserSession = useOutletContext();
    const navigate = useNavigate();
    const [input, setInput] = React.useState("");
    const [input2, setInput2] = React.useState("");
    const [input3, setInput3] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);

    const createItem = () => {
        setIsLoading(true);
        axios.post(apigateway +`/items`, {input, input2, input3},{
            headers: {
                Authorization: session.getIdToken().getJwtToken()
            }
        }).then((()=> {
            setIsLoading(false);
            navigate('/pages', {state: {message: "Page created successfully"}});
        }));
    }
    return <ContentLayout
    header={<Box padding={"m"}><Header variant={"awsui-h1-sticky"} description={"Enter details to create a new page"}>
        Create Page
    </Header></Box>}>
        <Container>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button formAction="none" variant="link" onClick={()=>navigate(-1)}>
                                Cancel
                            </Button>
                            <Button variant="primary" loading={isLoading} onClick={createItem}>Submit</Button>
                        </SpaceBetween>
                    }
                >
                    <SpaceBetween size={"m"}>
                        <FormField
                            label={"Enter input one"}
                            description={"This is an input to the pages object"}>
                            <Input value={input} onChange={(e)=>{setInput(e.detail.value)}}/>
                        </FormField>
                        <FormField
                            label={"Enter input two"}
                            description={"This is another input to the pages object"}>
                            <Input value={input2} onChange={(e)=>{setInput2(e.detail.value)}}/>
                        </FormField>
                        <FormField
                            label={"Enter input three"}
                            description={"This is yet another input to the pages object"}>
                            <Input value={input3} onChange={(e)=>{setInput3(e.detail.value)}}/>
                        </FormField>
                    </SpaceBetween>
                </Form>
        </Container>
    </ContentLayout>
}
