import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
    redirect,
} from "react-router-dom";
import './index.css';
import Home from './routes/Home';
import reportWebVitals from './reportWebVitals';
import ErrorPage from "./error-page";
import SignIn from "./routes/SignIn";
import userPool from "./cognito";
import Register from "./routes/Register";
import Verify from "./routes/Verify";
import Pages from "./routes/sidenav/Pages";
import UnderConstruction from "./routes/sidenav/UnderConstruction";
import Page from "./routes/sidenav/Page";
import PageCreate from "./routes/sidenav/PageCreate";

const getSession = async () => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
        return redirect("/signin");
    }
    const userDetails = await new Promise((resolve, reject) => {
        cognitoUser.getSession((err: any, session: any) => {
            if (err) {
                reject(err);
            } else if (!session.isValid()) {
                resolve(null);
            } else {
                resolve(session);
            }
        });
    });
    if (!userDetails) {
        return redirect("/signin")
    } else {
        return userDetails;
    }
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <ErrorPage />,
        loader: getSession,
        children: [
            {
                index: true,
                element: <UnderConstruction/>
            },
            {
                path: 'pages',
                element: <Pages/>,
            },
            {
                path: 'pages/:id',
                element: <Page/>,
            },
            {
                path: 'pages/create',
                element: <PageCreate/>,
            },
            {
                path: 'users',
                element: <UnderConstruction/>
            },
            {
                path: 'database',
                element: <UnderConstruction/>
            },
            {
                path: 'authentication',
                element: <UnderConstruction/>
            },
            {
                path: 'analytics',
                element: <UnderConstruction/>
            },
            {
                path: 'predictions',
                element: <UnderConstruction/>
            },
            {
                path: 'interactions',
                element: <UnderConstruction/>
            },
            {
                path: 'notifications',
                element: <UnderConstruction/>
            }

        ]
    },
    {
        path: "/signin",
        element: <SignIn />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/register",
        element: <Register />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/verify",
        element: <Verify />,
        errorElement: <ErrorPage />,
    }
]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
      <RouterProvider router={router} />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
