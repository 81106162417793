import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import "../styles/SignIn.css"
import {
    Container,
    Input,
} from "@cloudscape-design/components";
import React from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import userPool from "../cognito";
import { useNavigate, useLocation } from "react-router-dom";

export default function Verify() {
    const {state} = useLocation();
    const [email, setEmail] = React.useState(state?.email);
    const [code, setCode] = React.useState("");
    const navigate = useNavigate();

    async function verify() {
        const cognitoUser = new CognitoUser({Username : email, Pool : userPool});
        cognitoUser.confirmRegistration(code, true, (error, result) => {
            if (error) {
                alert(error);
            } else {
                console.log(result);
                navigate("/");
            }
        });
    }

    return (
      <>
          <Container
              className={"InputsBox"}
              header={
                  <Header
                      variant="h3"
                      description="Enter your verification code"
                  >
                      Login
                  </Header>
              }
              footer={<Button onClick={verify}>Verify</Button>}>
              <SpaceBetween size={"m"}>
                  <Input type={"email"}
                         value={email}
                         placeholder={"Email"}
                         onChange={({ detail }) => setEmail(detail.value)}
                         disabled={state?.email}
                  />
                  <Input type={"text"}
                         value={code}
                         placeholder={"Code"}
                         onChange={({ detail }) => setCode(detail.value)}
                  />
              </SpaceBetween>
          </Container>
      </>

  );
}
