import { Header } from "@cloudscape-design/components";
import React from "react";

export default function UnderConstruction() {
    return <Header
        variant="h2"
        description="This page to be updated soon..."
    >
        Under Construction
    </Header>
}
